// REDWOOD
import { Form, FormError, Submit } from "@redwoodjs/forms"
// COMPONENTS
import FormTextField from "src/components/FormTextField"
import FormNumberField from "src/components/FormNumberField"
import FormSelectField from "src/components/FormSelectField"

// INTERFACE
interface MotorFormProps {
  motor?: any
  onSave: any
  onCancel: any
  loading: any
  error: any
}

// COMPONENT FUNCTION
const MotorForm: React.FC<MotorFormProps> = ({
  motor,
  onSave,
  onCancel,
  loading,
  error,
}) => {
  // HANDLERS
  const onSubmit = (data) => {
    onSave(data)
  }

  // COMPONENT
  return (
    <div className="mb-8 md:max-w-3xl mx-auto">
      <Form onSubmit={onSubmit} error={error}>
        <FormError
          error={error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mt-4 mb-4"
          titleClassName="mt-0 font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <div className="grid grid-cols-3 gap-8 mb-8">
          <div className="col-span-3">
            <FormSelectField
              label="Motor Type"
              attribute="motorType"
              defaultValue={motor?.motorType}
              width={64}
              validation={{ required: true }}
              options={["OUTBOARD", "TWO STROKE", "FOUR STROKE", "OTHER"]}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormTextField
              label="Make"
              attribute="make"
              defaultValue={motor?.make}
              width={64}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <FormTextField
              label="HP"
              attribute="horsepower"
              defaultValue={motor?.horsepower}
              width={16}
              validation={{ required: true }}
            />
          </div>
        </div>
        <Submit
          disabled={loading}
          className="bg-lhBlue text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          {motor ? "Update" : "Create"}
        </Submit>
        <button
          onClick={onCancel}
          disabled={loading}
          className="ml-4 bg-lhWhite text-lhBlue active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          Cancel
        </button>
      </Form>
    </div>
  )
}

export default MotorForm
