const ServiceYardDetail: React.FunctionComponent = () => {
  const strokeColor = "#595959"
  return (
    <>
      {/* START DRIVEWAY */}
      <path d="M10 220h365 M10 260h365" stroke={strokeColor} strokeWidth="1" />
      <g
        stroke={strokeColor}
        strokeWidth="0"
        transform="translate(60, 225) scale(0.25)"
      >
        <rect id="bsmLogo" x="0" y="0" fill="#315db6" width="89" height="121" />
        <polygon fill="#ffffff" points="43,4 43,117 4,117" />
        <polygon fill="#ffffff" points="48,4 48,117 85,117" />
      </g>
      <text x="100" y="250" fill={strokeColor} fontSize="24" fontWeight="600">
        BSM SERVICE YARD
      </text>
      {/* END DRIVEWAY */}

      {/* START COMPASS */}
      <text x="500" y="365" fill={strokeColor} fontSize="24" fontWeight="900">
        N
      </text>
      <path
        d="M500 384L509 375M509 375L518 384M509 375L509 395"
        stroke={strokeColor}
        strokeWidth="2"
      />
      {/* END COMPASS */}

      {/* START OFFICE */}
      <path
        d="M156 65h73v120h-73Z"
        stroke={strokeColor}
        strokeWidth="1"
        fill="#f2f2f2"
      />
      <text x="172" y="135" fill={strokeColor}>
        Office
      </text>
      {/* END OFFICE */}

      {/* START LEGEND */}
      <g fill={strokeColor} fontWeight="400">
        <g stroke="#0d0d0d" strokeWidth="1">
          <path d="M300 340h16v16h-16Z" fill="#13ad36" />
          <path d="M300 375h16v16h-16Z" fill="#faf566" />
          <path d="M300 410h16v16h-16Z" fill="#fa4d5d" />
        </g>
        <text x="325" y="354">
          Empty
        </text>
        <text x="325" y="389">
          Partially Full
        </text>
        <text x="325" y="418">
          Full
        </text>
      </g>
      {/* END LEGEND */}
    </>
  )
}

export default ServiceYardDetail
