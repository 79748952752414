import { Link, routes } from "@redwoodjs/router"
import moment from "moment"

interface RecentActivityProps {
  activities: any
}

type Activity = {
  id: number
  createdAt: Date
  message: string
}

const RecentActivity: React.FC<RecentActivityProps> = ({ activities }) => {
  return activities.map((activity: Activity) => {
    return (
      <div key={activity.id} className="text-sm">
        <p className="mb-4">
          <span className="font-semibold">
            {moment(activity.createdAt).format("MMM DD YYYY")}
          </span>{" "}
          <Link to={routes.activity({ id: activity.id })}>
            {activity.message.toUpperCase()}
          </Link>
        </p>
      </div>
    )
  })
}

export default RecentActivity
