interface SubSectionHeaderProps {
  name: string
}

const SubSectionHeader: React.FC<SubSectionHeaderProps> = ({ name }) => {
  return (
    <h3 className="inline-block capitalize text-md text-lhDarkGray font-bold italic mb-2">
      {name}
    </h3>
  )
}

export default SubSectionHeader
