import { Link, routes } from "@redwoodjs/router"
import { getCapacityColor } from "src/utilities/colorStatus"
import StorageYardDetail from "src/components/StorageYardDetail"
import ServiceYardDetail from "src/components/ServiceYardDetail"

interface YardMapProps {
  place: {
    id: number
    name: string
    locations: Location[]
  }
  links: boolean
}

type Location = {
  id: number
  name: string
  sqftLoad: number
  sqftCapacity: number
  oa: number
  ox: number
  oy: number
  rw: number
  rh: number
  rx: number
  ry: number
  tx: number
  ty: number
}

const YardMap: React.FC<YardMapProps> = ({ place, links }) => {
  return (
    <div className="w-full md:w-3/5 max-w-4xl mx-auto">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 600 480"
        className="max-h-full"
      >
        {place.locations.map((location) => {
          const load = location.sqftLoad / location.sqftCapacity
          const { rx, ry, rw, rh } = location
          return (
            <g
              key={location.id}
              transform={`rotate(${location.oa} ${location.ox} ${location.oy})`}
              fill="#f2f2f2"
              stroke="#0d0d0d"
              strokeWidth={1}
            >
              {links ? (
                <Link to={routes.location({ name: location.name })}>
                  <path
                    fill={getCapacityColor(load)}
                    d={`M${rx} ${ry}h${rw}v${rh}h${rw * -1}Z`}
                  />
                  <text x={location.tx} y={location.ty} fill="#0d0d0d">
                    {location.name}
                  </text>
                </Link>
              ) : (
                <>
                  <path
                    fill={getCapacityColor(load)}
                    d={`M${rx} ${ry}h${rw}v${rh}h${rw * -1}Z`}
                  />
                  <text x={location.tx} y={location.ty} fill="#0d0d0d">
                    {location.name}
                  </text>
                </>
              )}
            </g>
          )
        })}
        {place.name === "STORAGE YARD" && <StorageYardDetail />}
        {place.name === "SERVICE YARD" && <ServiceYardDetail />}
      </svg>
    </div>
  )
}

export default YardMap
