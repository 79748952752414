import Header from "src/components/Header"
import Title from "src/components/Title"
import Footer from "src/components/Footer"
import { useAuth } from "@redwoodjs/auth"
import LighthouseLogo from "../images/lighthouse-logo.svg"

const AppLayout = ({ title, color = "#f2f2f2", children }) => {
  const { isAuthenticated, logIn } = useAuth()
  return (
    <>
      {isAuthenticated ? (
        <>
          <Header />
          <Title title={title} color={color} />
          <main className="p-4">{children}</main>
          <Footer />
        </>
      ) : (
        <div className="w-4/5 max-w-2xl mt-64 mx-auto">
          <LighthouseLogo />
          <button
            onClick={logIn}
            className="block mb-16 mt-8 mx-auto bg-lhWhite text-lhBlue hover:bg-lhBlue hover:text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
          >
            Login
          </button>
          <p className="text-center text-lhGray text-xs">
            FOR BAY SAILS MARINE EMPLOYEE USE ONLY
          </p>
        </div>
      )}
    </>
  )
}

export default AppLayout
