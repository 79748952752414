interface TitleProps {
  title: string
  color: string
}

const Title: React.FunctionComponent<TitleProps> = ({ title, color }) => {
  return (
    <div className="sticky top-0 bg-lhWhite">
      {title && (
        <h1
          style={{ backgroundColor: color }}
          className="uppercase font-display text-center text-lhBlack text-2xl border-solid border-t-2 border-b-2 border-lhBlack my-3 py-1 px-4"
        >
          {title}
        </h1>
      )}
    </div>
  )
}

export default Title
