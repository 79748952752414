interface SectionHeaderProps {
  name: string
}

const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({
  name,
}) => {
  return (
    <h2 className="inline-block uppercase text-xl text-lhBlack border-b border-solid border-lhBlack mb-4">
      {name}
    </h2>
  )
}

export default SectionHeader
