import { useAuth } from "@redwoodjs/auth"

const UserMenu: React.FunctionComponent = () => {
  const { logIn, logOut, isAuthenticated, currentUser } = useAuth()
  return (
    <>
      {isAuthenticated ? (
        <div className="text-lhBlack text-lg text-center mt-3 mr-6">
          <a href="#" onClick={logOut} className="block mt-2">
            Logout
          </a>
        </div>
      ) : (
        <div className="text-lhBlack text-lg text-center mt-3 mr-6">
          <a href="#" onClick={logIn} className="block mt-2">
            Login
          </a>
        </div>
      )}
    </>
  )
}

export default UserMenu
