import {
  Form,
  FormError,
  Submit,
  FieldError,
  Label,
  TextField,
} from "@redwoodjs/forms"

const CSS = {
  label: "block",
  labelError: "block font-semibold text-red-700",
  input:
    "block p-2 w-32 border border-lhLightGray focus:outline-none focus:border-lhBlue text-right",
  inputError:
    "block p-2 border border-red-700 text-red-900 rounded focus:outline-none",
  errorMessage: "block mt-1 font-semibold uppercase text-xs text-red-700",
  submit:
    "bg-lhWhite text-lhBlue hover:bg-lhBlue hover:text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide",
}

interface RateFormProps {
  onSave: any
  onCancel: any
  rateObject: any
  loading: any
  error: any
  rates: string[]
  name: string
}

const RateForm: React.FC<RateFormProps> = ({
  onSave,
  onCancel,
  rateObject,
  loading,
  error,
  rates,
  name,
}) => {
  const formatKey = (rate: string) => {
    return rate[0].toLowerCase() + rate.substring(1).replace(/\s/gi, "")
  }

  const formatRate = (rate: string) => {
    if (rate[0] === "r") {
      return rate.substring(1).replace("to", " - ").replace("plus", "+")
    }
    return rate
  }

  const onSubmit = (data) => {
    Object.keys(data).forEach((key) => (data[key] = parseFloat(data[key])))
    onSave(data, rateObject.id)
  }

  return (
    <div>
      <Form onSubmit={onSubmit} error={error}>
        <FormError
          error={error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mt-4 mb-4"
          titleClassName="mt-0 font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <table className="table-auto mx-auto border-collapse">
          <thead>
            <tr>
              <th className="text-left">{name}</th>
              <th className="text-right">Rate</th>
            </tr>
          </thead>
          <tbody>
            {rates.map((rate) => (
              <tr key={formatKey(rate)}>
                <td className="border border-solid border-lhBlack p-2">
                  <Label
                    name={formatRate(rate)}
                    className={CSS.label}
                    errorClassName={CSS.labelError}
                  />
                </td>
                <td className="border border-solid border-lhBlack p-2 text-right">
                  <TextField
                    name={formatKey(rate)}
                    defaultValue={rateObject[formatKey(rate)]}
                    className={CSS.input}
                    errorClassName={CSS.inputError}
                    validation={{ required: true }}
                  />
                  <FieldError
                    name={formatKey(rate)}
                    className={CSS.errorMessage}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="mt-8 text-center">
          <Submit
            disabled={loading}
            className="bg-lhBlue text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
          >
            Update
          </Submit>
          <button
            onClick={onCancel}
            disabled={loading}
            className="ml-4 bg-lhWhite text-lhBlue active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
          >
            Cancel
          </button>
        </div>
      </Form>
    </div>
  )
}

export default RateForm
