import { Link, routes } from "@redwoodjs/router"
import Places from "../../images/map-marker.svg"
import Customers from "../../images/users.svg"
import Units from "../../images/ship.svg"
import Rates from "../../images/sliders.svg"

const CSS = {
  description: "text-lg sm:inline",
  footer:
    "grid grid-cols-4 h-16 sm:h-12 w-full fixed left-0 bottom-0 bg-lhBlack text-lhWhite text-center",
  icon:
    "fill-current text-lhWhite block h-8 mx-auto mt-1 sm:inline sm:w-1/5 sm:m-2",
}

const Footer: React.FunctionComponent = () => {
  return (
    <footer className={CSS.footer}>
      <div>
        <Link to={routes.places()}>
          <Places className={CSS.icon} />
          <p className={CSS.description}>Places</p>
        </Link>
      </div>
      <div>
        <Link to={routes.customers()}>
          <Customers className={CSS.icon} />
          <p className={CSS.description}>Customers</p>
        </Link>
      </div>
      <div>
        <Link to={routes.units()}>
          <Units className={CSS.icon} />
          <p className={CSS.description}>Units</p>
        </Link>
      </div>
      <div>
        <Link to={routes.rates()}>
          <Rates className={CSS.icon} />
          <p className={CSS.description}>Rates</p>
        </Link>
      </div>
    </footer>
  )
}

export default Footer
