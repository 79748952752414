// REDWOOD
import {
  Form,
  FormError,
  Submit,
  FieldError,
  Label,
  CheckboxField,
} from "@redwoodjs/forms"
// COMPONENTS
import FormTextField from "src/components/FormTextField"
import FormNumberField from "src/components/FormNumberField"
import FormSelectField from "src/components/FormSelectField"

// INTERFACE
interface BoatFormProps {
  boat?: any
  onSave: any
  onCancel: any
  loading: any
  error: any
}

// COMPONENT FUNCTION
const BoatForm: React.FC<BoatFormProps> = ({
  boat,
  onSave,
  onCancel,
  loading,
  error,
}) => {
  // HANDLERS
  const onSubmit = (data) => {
    onSave(data)
  }

  // COMPONENT
  return (
    <div className="mb-8 md:max-w-3xl mx-auto">
      <Form onSubmit={onSubmit} error={error}>
        <FormError
          error={error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mt-4 mb-4"
          titleClassName="mt-0 font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <div className="grid grid-cols-3 gap-8 mb-8">
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormTextField
              label="Make"
              attribute="make"
              defaultValue={boat?.make}
              width={64}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <FormTextField
              label="Model Length"
              attribute="modelLength"
              defaultValue={boat?.modelLength}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3">
            <FormSelectField
              label="Boat Type"
              attribute="boatType"
              defaultValue={boat?.boatType}
              width={48}
              validation={{ required: true }}
              options={["POWER", "SAIL"]}
            />
          </div>
          <div className="col-span-3">
            <FormSelectField
              label="Fuel Type"
              attribute="fuelType"
              defaultValue={boat?.fuelType}
              width={48}
              validation={{ required: true }}
              options={["GAS", "DIESEL"]}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormNumberField
              label="Estimated Fuel (Gallons)"
              attribute="fuelEstimatedGallons"
              defaultValue={boat?.fuelEstimatedGallons}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <FormNumberField
              label="Fuel Capacity (Gallons)"
              attribute="fuelCapacity"
              defaultValue={boat?.fuelCapacity}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3">
            <FormNumberField
              label="Battery Count"
              attribute="batteryCount"
              defaultValue={boat?.batteryCount}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3">
            <FormNumberField
              label="Boat Only Length (inches)"
              attribute="boatOnlyLength"
              defaultValue={boat?.boatOnlyLength}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormNumberField
              label="Boat Length (inches)"
              attribute="boatLength"
              defaultValue={boat?.boatLength}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <FormNumberField
              label="Boat Width (inches)"
              attribute="boatWidth"
              defaultValue={boat?.boatWidth}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormSelectField
              label="Haul Location"
              attribute="haulLocation"
              defaultValue={boat?.haulLocation}
              width={64}
              validation={{ required: true }}
              options={[
                "ORLEANS",
                "EASTHAM",
                "WELLFLEET",
                "TRURO",
                "PROVINCETOWN",
                "OTHER",
                "NOT A HAUL CUSTOMER",
              ]}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <Label
              name="Permit Required?"
              className="block"
              errorClassName="block font-semibold text-red-700"
            />
            <CheckboxField
              name="permit"
              defaultChecked={boat?.permit}
              errorClassName="block p-2 border border-red-700 text-red-900 rounded focus:outline-none"
            />
            <FieldError
              name="permit"
              className="block mt-1 font-semibold uppercase text-xs text-red-700"
            />
          </div>
        </div>
        <Submit
          disabled={loading}
          className="bg-lhBlue text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          {boat ? "Update" : "Create"}
        </Submit>
        <button
          onClick={onCancel}
          disabled={loading}
          className="ml-4 bg-lhWhite text-lhBlue active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          Cancel
        </button>
      </Form>
    </div>
  )
}

export default BoatForm
