import { Link, routes } from "@redwoodjs/router"
import UserMenu from "src/components/UserMenu"
import LighthouseLogo from "../../images/lighthouse-logo.svg"

const Header: React.FC = () => {
  return (
    <div className="grid grid-cols-3" style={{ justifyItems: "end" }}>
      <div className="col-span-2 w-full mr-auto">
        <Link to={routes.home()}>
          <LighthouseLogo style={{ height: "3rem" }} />
        </Link>
      </div>
      <div className="col-start-3 col-end-4">
        <UserMenu />
      </div>
    </div>
  )
}

export default Header
