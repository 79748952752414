const StorageYardDetail: React.FunctionComponent = () => {
  const strokeColor = "#595959"

  return (
    <>
      {/* START ROUTE 6*/}
      <path d="M10 0v470 M35 0v470" stroke={strokeColor} strokeWidth="1" />
      <g transform="rotate(90 10 10)">
        <text x="180" y="3" fill={strokeColor} fontSize="16">
          2220 ROUTE 6
        </text>
      </g>
      {/* END ROUTE 6*/}

      {/* START DRIVEWAY */}
      <path d="M40 250h415 M40 290h415" stroke={strokeColor} strokeWidth="1" />
      <g
        stroke={strokeColor}
        strokeWidth="0"
        transform="translate(110, 255) scale(0.25)"
      >
        <rect id="bsmLogo" x="0" y="0" fill="#315db6" width="89" height="121" />
        <polygon fill="#ffffff" points="43,4 43,117 4,117" />
        <polygon fill="#ffffff" points="48,4 48,117 85,117" />
      </g>
      <text x="145" y="280" fill={strokeColor} fontSize="24" fontWeight="600">
        BSM STORAGE YARD
      </text>
      {/* END DRIVEWAY */}

      {/* START COMPASS */}
      <text x="300" y="25" fill={strokeColor} fontSize="24" fontWeight="900">
        N
      </text>
      <path
        d="M300 44L309 35M309 35L318 44M309 35L309 55"
        stroke={strokeColor}
        strokeWidth="2"
      />
      {/* END COMPASS */}

      {/* START LEGEND */}
      <g fill={strokeColor} fontWeight="400">
        <g stroke="#0d0d0d" strokeWidth="1">
          <path d="M465 320h16v16h-16Z" fill="#13ad36" />
          <path d="M465 355h16v16h-16Z" fill="#faf566" />
          <path d="M465 390h16v16h-16Z" fill="#fa4d5d" />
        </g>
        <text x="490" y="334">
          Empty
        </text>
        <text x="490" y="369">
          Partially Full
        </text>
        <text x="490" y="404">
          Full
        </text>
      </g>
      {/* END LEGEND */}
    </>
  )
}

export default StorageYardDetail
