// REDWOOD
import {
  Form,
  FormError,
  Submit,
  FieldError,
  Label,
  SelectField,
} from "@redwoodjs/forms"
// COMPONENTS
import FormNumberField from "src/components/FormNumberField"

// INTERFACE
interface RigFormProps {
  rig?: any
  boats: any
  trailers: any
  onSave: any
  onCancel: any
  loading: any
  error: any
}

// COMPONENT FUNCTION
const RigForm: React.FC<RigFormProps> = ({
  rig,
  boats,
  trailers,
  onSave,
  onCancel,
  loading,
  error,
}) => {
  // HANDLERS
  const onSubmit = (data) => {
    onSave(data)
  }

  // COMPONENT
  return (
    <div className="mb-8 md:max-w-3xl mx-auto">
      <Form onSubmit={onSubmit} error={error}>
        <FormError
          error={error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mt-4 mb-4"
          titleClassName="mt-0 font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <div className="grid grid-cols-3 gap-8 mb-8">
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormNumberField
              label="Rig Length (inches)"
              attribute="rigLength"
              defaultValue={rig?.rigLength}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <FormNumberField
              label="Rig Width (inches)"
              attribute="rigWidth"
              defaultValue={rig?.rigWidth}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3">
            <Label
              name="Boat"
              className="block"
              errorClassName="block font-semibold text-red-700"
            />
            <SelectField
              name="boatId"
              defaultValue={rig?.boat.id}
              className="block p-2 mb-4 md:mb-0 border border-lhLightGray focus:outline-none focus:border-lhBlue text-left w-64"
              errorClassName="block p-2 border border-red-700 text-red-900 rounded focus:outline-none"
              validation={{ required: true }}
            >
              {boats?.map((boat) => (
                <option key={boat.boat.id} value={boat.boat.id}>
                  {boat.name}
                </option>
              ))}
            </SelectField>
            <FieldError
              name="boatId"
              className="block mt-1 font-semibold uppercase text-xs text-red-700"
            />
          </div>
          <div className="col-span-3">
            <Label
              name="Trailer"
              className="block"
              errorClassName="block font-semibold text-red-700"
            />
            <SelectField
              name="trailerId"
              defaultValue={rig?.trailer.id}
              className="block p-2 mb-4 md:mb-0 border border-lhLightGray focus:outline-none focus:border-lhBlue text-left w-64"
              errorClassName="block p-2 border border-red-700 text-red-900 rounded focus:outline-none"
              validation={{ required: true }}
            >
              {trailers?.map((trailer) => (
                <option key={trailer.trailer.id} value={trailer.trailer.id}>
                  {trailer.name}
                </option>
              ))}
            </SelectField>
            <FieldError
              name="trailerId"
              className="block mt-1 font-semibold uppercase text-xs text-red-700"
            />
          </div>
        </div>
        <Submit
          disabled={loading}
          className="bg-lhBlue text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          {rig ? "Update" : "Create"}
        </Submit>
        <button
          onClick={onCancel}
          disabled={loading}
          className="ml-4 bg-lhWhite text-lhBlue active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          Cancel
        </button>
      </Form>
    </div>
  )
}

export default RigForm
