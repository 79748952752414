// REDWOOD
import { Form, FormError, Submit } from "@redwoodjs/forms"
import { useForm } from "react-hook-form"
// COMPONENTS
import FormTextField from "src/components/FormTextField"
import FormNumberField from "src/components/FormNumberField"
import FormSelectField from "src/components/FormSelectField"

// INTERFACE
interface MooringFormProps {
  mooring?: any
  onSave: any
  onCancel: any
  loading: any
  error: any
}

// COMPONENT FUNCTION
const MooringForm: React.FC<MooringFormProps> = ({
  mooring,
  onSave,
  onCancel,
  loading,
  error,
}) => {
  // FORM METHODS
  const formMethods = useForm()

  // HANDLERS
  const onSubmit = (data) => {
    onSave(data)
  }

  const showPosition = (position: any) => {
    formMethods.setValue("latitude", position.coords.latitude)
    formMethods.setValue("longitude", position.coords.longitude)
  }

  const handleGetLocation = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }

  // COMPONENT
  return (
    <div className="mb-8 md:max-w-3xl mx-auto">
      <Form onSubmit={onSubmit} error={error} formMethods={formMethods}>
        <FormError
          error={error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mt-4 mb-4"
          titleClassName="mt-0 font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <div className="grid grid-cols-3 gap-8 mb-8">
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormSelectField
              label="Mooring Type"
              attribute="mooringType"
              defaultValue={mooring?.mooringType}
              width={48}
              validation={{ required: true }}
              options={["MUSHROOM", "OTHER"]}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <FormNumberField
              label="Weight (LBS)"
              attribute="weight"
              defaultValue={mooring?.weight}
              width={16}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3">
            <FormSelectField
              label="Harbor"
              attribute="harbor"
              defaultValue={mooring?.harbor}
              width={48}
              validation={{ required: true }}
              options={["WELLFLEET", "PAMET", "PROVINCETOWN", "OTHER"]}
            />
          </div>
          <div className="col-span-3">
            <FormTextField
              label="Tag #"
              attribute="tagNumber"
              defaultValue={mooring?.tagNumber}
              width={48}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-1">
            <FormTextField
              label="Latitude"
              attribute="latitude"
              defaultValue={mooring?.latitude}
              width={32}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-2">
            <FormTextField
              label="Longitude"
              attribute="longitude"
              defaultValue={mooring?.longitude}
              width={32}
              validation={{ required: true }}
            />
          </div>
          <div className="col-span-3 md:col-span-1 md:col-start-3">
            <button
              onClick={handleGetLocation}
              disabled={loading}
              className="mt-5 mb-8 bg-lhWhite text-lhBlue active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
            >
              Get Location
            </button>
          </div>
        </div>
        <Submit
          disabled={loading}
          className="bg-lhBlue text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          {mooring ? "Update" : "Create"}
        </Submit>
        <button
          onClick={onCancel}
          disabled={loading}
          className="ml-4 bg-lhWhite text-lhBlue active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide"
        >
          Cancel
        </button>
      </Form>
    </div>
  )
}

export default MooringForm
