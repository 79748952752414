// REDWOOD
import { FieldError, Label, NumberField } from "@redwoodjs/forms"

// INTERFACE
interface FormNumberFieldProps {
  label: string
  attribute: string
  defaultValue: any
  width: number
  validation: {}
}

// COMPONENT FUNCTION
const FormNumberField: React.FC<FormNumberFieldProps> = ({
  label,
  attribute,
  defaultValue,
  width,
  validation,
}) => {
  // COMPONENT
  return (
    <>
      <Label
        name={label}
        className="block"
        errorClassName="block font-semibold text-red-700"
      />
      <NumberField
        name={attribute}
        defaultValue={defaultValue}
        className={`block p-2 mb-4 md:mb-0 border border-lhLightGray focus:outline-none focus:border-lhBlue text-left w-${width}`}
        errorClassName="block p-2 border border-red-700 text-red-900 rounded focus:outline-none"
        validation={validation}
      />
      <FieldError
        name={attribute}
        className="block mt-1 font-semibold uppercase text-xs text-red-700"
      />
    </>
  )
}

export default FormNumberField
