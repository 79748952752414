import {
  Form,
  FormError,
  Submit,
  FieldError,
  Label,
  TextField,
} from "@redwoodjs/forms"

const CSS = {
  fields: "mb-8",
  label: "block",
  labelError: "block font-semibold text-red-700",
  input:
    "block p-2 mb-4 md:mb-0 border border-lhLightGray focus:outline-none focus:border-lhBlue text-left",
  inputError:
    "block p-2 border border-red-700 text-red-900 rounded focus:outline-none",
  errorMessage: "block mt-1 font-semibold uppercase text-xs text-red-700",
  submit:
    "bg-lhWhite text-lhBlue hover:bg-lhBlue hover:text-lhWhite active:bg-lhDarkBlue border-2 border-solid border-lhBlue px-4 py-2 uppercase font-semibold tracking-wide",
}

interface CustomerFormProps {
  customer?: any
  onSave: any
  loading: any
  error: any
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  customer,
  onSave,
  loading,
  error,
}) => {
  const onSubmit = (data) => {
    onSave(data)
  }

  return (
    <div className="mb-8 md:max-w-2xl mx-auto">
      <Form onSubmit={onSubmit} error={error}>
        <FormError
          error={error}
          wrapperClassName="p-4 bg-red-100 text-red-700 border border-red-300 rounded mt-4 mb-4"
          titleClassName="mt-0 font-semibold"
          listClassName="mt-2 list-disc list-inside"
        />

        <div className={CSS.fields}>
          <div className="md:inline-block">
            <Label
              name="First Name"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="firstName"
              defaultValue={customer?.firstName}
              className={`${CSS.input}, w-48`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="firstName" className={CSS.errorMessage} />
          </div>
          <div className="md:inline-block md:ml-4">
            <Label
              name="Last Name"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="lastName"
              defaultValue={customer?.lastName}
              className={`${CSS.input}, w-64`}
              errorClassName={CSS.inputError}
              validation={{ required: true }}
            />
            <FieldError name="lastName" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div className="md:inline-block">
            <Label
              name="Address 1"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="address1"
              defaultValue={customer?.address1}
              className={`${CSS.input}, w-64`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="address1" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div className="md:inline-block">
            <Label
              name="Address 2"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="address2"
              defaultValue={customer?.address2}
              className={`${CSS.input}, w-64`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="address2" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div className="md:inline-block">
            <Label
              name="City"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="city"
              defaultValue={customer?.city}
              className={`${CSS.input}, w-64`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="city" className={CSS.errorMessage} />
          </div>
          <div className="inline-block md:ml-2">
            <Label
              name="State"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="state"
              defaultValue={customer?.state}
              className={`${CSS.input}, w-12`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="state" className={CSS.errorMessage} />
          </div>
          <div className="inline-block ml-2">
            <Label
              name="Zip"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="zip"
              defaultValue={customer?.zip}
              className={`${CSS.input}, w-24`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="zip" className={CSS.errorMessage} />
          </div>
          <div className="inline-block ml-2">
            <Label
              name="Zip +4"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="zip4"
              defaultValue={customer?.zip4}
              className={`${CSS.input}, w-24`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="zip4" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div className="md:inline-block">
            <Label
              name="Home Phone"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="homePhone"
              defaultValue={customer?.homePhone}
              className={`${CSS.input}, w-48`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="homePhone" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div className="md:inline-block">
            <Label
              name="Work Phone"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="workPhone"
              defaultValue={customer?.workPhone}
              className={`${CSS.input}, w-48`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="workPhone" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div className="md:inline-block">
            <Label
              name="Mobile Phone"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="mobilePhone"
              defaultValue={customer?.mobilePhone}
              className={`${CSS.input}, w-48`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="mobilePhone" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div>
            <Label
              name="Email"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="email"
              defaultValue={customer?.email}
              className={`${CSS.input}, w-full md:max-w-lg`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="email" className={CSS.errorMessage} />
          </div>
        </div>
        <div className={CSS.fields}>
          <div>
            <Label
              name="Comments"
              className={CSS.label}
              errorClassName={CSS.labelError}
            />
            <TextField
              name="comments"
              defaultValue={customer?.comments}
              className={`${CSS.input}, w-full md:max-w-lg`}
              errorClassName={CSS.inputError}
            />
            <FieldError name="comments" className={CSS.errorMessage} />
          </div>
        </div>
        <Submit disabled={loading} className={CSS.submit}>
          {customer ? "Update" : "Create"}
        </Submit>
      </Form>
    </div>
  )
}

export default CustomerForm
